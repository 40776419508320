/* ==========================================================================
   logo
   ========================================================================== */

.c-logo {
    display: block;
    width: fit-content;
    
    a {
        display: inline-block;
    }

    &__image {
        display: block;
        height: auto;
    }

    &--header {
        @include breakpoint (medium down) {
            margin-left: 50%;
            transform: translateX(-50%);
        }

        @include breakpoint (large) {
            margin-right: rem(20);
        }

        .c-logo__image {
            width: rem(93);
            height: rem(52);
    
            @include breakpoint(large) {
                width: rem(132);
                height: rem(74);
            }
        }
    }

    &--footer {
        width: auto;
        
        a {
            text-align: center;
        }
        
       .c-logo__image {
            display: inherit; // needed to give size to wrapping href
            max-width: rem(100);
       }
       
       @media screen and (min-width: rem(1180)) {
           text-align: center; // centers rideonline logo
       }
       
    }

    &--menu {
        margin: rem(30 auto 60);

        .c-logo__image {
            width: rem(100);
        }
    }
}
