/* ==========================================================================
#ATOM STYLES
========================================================================== */
$utilprefixclass: '' !default; // required for editor.css
/**
* Atom Styles
*
* Used to add the following atomic styles:
*
* 1. margin styles
* 2. padding styles
* 3. js, no-js
* 4. text color
* 5. background color
* 6. visually hidden
*/

@media not print {

    .#{$utilprefixclass}u-no-margin {
        margin: 0 !important;
    }
    .#{$utilprefixclass}u-no-margin--top {
        margin-top: 0 !important;
    }
    .#{$utilprefixclass}u-no-margin--bottom {
        margin-bottom: 0 !important;
    }

    .#{$utilprefixclass}u-no-padding {
        padding: 0 !important;
    }
    .#{$utilprefixclass}u-no-padding--top {
        padding-top: 0 !important;
    }
    .#{$utilprefixclass}u-no-padding--bottom {
        padding-bottom: 0 !important;
    }

    @each $name, $size in $extra-class-sizes {
        .#{$utilprefixclass}u-extra-#{$name}-margin--top {
            margin-top: $size !important;
        }
        .#{$utilprefixclass}u-extra-#{$name}-margin--bottom {
            margin-bottom: $size !important;
        }
        .#{$utilprefixclass}u-extra-#{$name}-padding--top {
            padding-top: $size !important;
        }
        .#{$utilprefixclass}u-extra-#{$name}-padding--bottom {
            padding-bottom: $size !important;
        }
    }
}

html.no-js {
    .u-show-with-js {
        display: none !important;
    }
}
html.js {
    .u-hide-with-js {
        display: none !important;
    }
}

@each $name, $color in map-merge($foundation-palette, (white: #fff)) {
    .#{$utilprefixclass}u-color--#{$name} {
        color: $color;
        fill: $color;
        @media print {
            color: #000;
        }
    }
}

@each $name, $color in map-merge($foundation-palette, (light: $light-gray)) {
    .#{$utilprefixclass}u-background--#{$name} {
        background: rgba($color, $global-transparency);

        > *, > div > * {
            color: color-pick-contrast($color, ($white, $body-font-color), 0.6);
            fill: color-pick-contrast($color, ($white, $body-font-color), 0.6);
        }
    }
}

.u-bg-no-repeat {
    background-repeat: no-repeat;
    background-size: cover;    
}

.u-text-center {
    text-align: center !important;
}

.u-visually-hidden {
    @include visually-hidden;
}

.u-visually-hidden-undo {
    @include visually-hidden-undo;
}
   
.u-overlay-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.u-disable-pointer-events {
    pointer-events: none;
}

.u-rotate {
    transform: rotateY(180deg);

    @media (prefers-reduced-motion: no-preference) {
        transition: transform 0.6s;
    }
}

.u-line-bottom--for-small {
    @include breakpoint (small only) {
        &:after {
            display: block;
            content: '';
            height: 1px;
            margin: rem(50) 0;
            background: $light-blue;
        }

    }
}
