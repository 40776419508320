/* ==========================================================================
   Radioplayer
   ========================================================================== */

.c-radioplayer {
    height: rem(60);
    background-color: $secondary-color;

    img {
        height: rem(60);
        width: rem(60);
    }

    &__trackinfo {
        color: $white;
        font-weight: 700;
        font-size: rem(12);
        margin: rem(0 20);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    &__playbutton:hover {
        color: lighten($primary-color, 20%);
    }

    .icon--radioplayer {
        display: block;
        height: rem(36);
        width: rem(36);
        margin: rem(10 25);
    }
}