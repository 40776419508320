/* ==========================================================================
   social links
   ========================================================================== */

/**
 * social links
 */
 .c-social-links {
    margin-left: auto;
    margin-right: auto;
    list-style-type: none;
    display: flex;
    width: rem(150);
    justify-content: space-between;

    .icon--social {
        width: rem(21);
        height: rem(21);
        transition: fill .3s ease-in-out;

        &:hover {
            fill: $secondary-color;
        }
    }

    &--mobile-nav {
        margin-top: rem(100);
    }

    &--accordion {
        margin: rem(0 0 5);

        .icon--social:hover {
            fill: $white;
        }
    }

    &--soundmemory {
        margin-top: rem(20);
        width: rem(100);

        .icon--social {
            fill: $white;
        }
    }
}