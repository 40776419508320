@charset "UTF-8";
// http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

// import foundation settins and scss
@import 'node_modules/modern-css-reset/src/reset';
@import "node_modules/foundation-sites/scss/util/util";
@import "functions";
@import "settings";
@import "mixins";
@import "node_modules/foundation-sites/scss/foundation";

// Foundation Feature Includes
@include foundation-global-styles;
@include foundation-xy-grid-classes(
    // include only those you need...
    $base-grid: true,
    $margin-grid: true,
    $padding-grid: true,
    $block-grid: false,
    $collapse: false,
    $offset: true,
    $vertical-grid: false,
    $frame-grid: false
);
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;

@import 'node_modules/@splidejs/splide/src/css/themes/sea-green/index.scss';

// Local Imports
@import 'layout';
@import 'typography';
@import 'lazyload';
@import 'buttons';

@import 'components/nav';
@import 'components/hero';
@import 'components/slider';
@import 'components/footer-nav';
@import 'components/footer';
@import 'components/instagram-feed';
@import 'components/mobile-nav';
@import 'components/logo';
@import 'components/social-links';
@import 'components/accordion';
@import 'components/icon-badges';
@import 'components/logo-card';
@import 'components/newsletter';
@import 'components/sticky-button';
@import 'components/product';
@import 'components/soundmemory';
@import 'components/embed';
@import 'components/subnav';
@import 'components/radioplayer';
@import 'components/program';
@import 'components/news';
@import 'components/figureimage';

// Forms 
@import "forms/forms";

// Objects
@import "objects";

// Layouts
@import "layouts/pageheader";
@import "layouts/pagefooter";

// This should come late
@import 'utils';
@import 'animations';
@import 'print';

.c-debuginfo {
    z-index: 9;
    position: fixed;
    bottom: 6rem;
    right: 0;
    width: auto;
    margin: 1rem;
    padding: 1rem;
    background: cssvar(color-primary);
    color: cssvar(color-white);
    border: rem(3) solid cssvar(color-white);
    font-size: 0.75rem;
    opacity: 0.8;
}
