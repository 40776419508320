
.c-footer {
    
    position: relative;
    
    ul {
        list-style-type: none;
        margin: 0;
    }

    &__heading {
        color: $white;
        font-size: rem(16);
        margin-bottom: rem(20);
    }

    a {
        color: $white;
        font-size: rem(16);

        &:hover, &:focus {
            color: $secondary-color;
        }
    }
    
    p.h5 {
        @include breakpoint (medium) {
            margin-top: 0;
        }
    }
    
    &__apps {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: rem(159); // On large screens, if the embed html is shown, the box smashes the app div and images
    }

    &__apps-list {
        display: flex;
        flex-direction: column;
        gap: rem(15);
    }
    
    &__podcast {
        width: rem(280); // podigee embed script needs at least this much space
        margin-bottom: $global-margin;
    }
    
    &__social-media {
        margin-bottom: $global-margin*2;
    }
    
    @include breakpoint (small only) {
        .c-social-links {
            width: 100%;
        }
    }
}
