/* ==========================================================================
   header layout
   ========================================================================== */

/**
 * base styles
 */

.l-page-header {
    position: fixed;
    top: 0;
    padding: rem(20);
    max-height: rem(92);
    z-index: 3;
    box-shadow: $box-shadow-dark;
    background: $white;
    
    @include breakpoint (medium down) {
        .grid-container {
            padding: 0;
        }
    }

    @include breakpoint (large) {
        max-height: rem(114);
    }
 
    .grid-container {
        position: relative; // brings the content above the background images
        z-index: 3;
    }
}
