/* open sans regular latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local("Open Sans"), local("Open-Sans"), url("open-sans-v18-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("open-sans-v18-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open sans 600 latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
    src: local("Open Sans 600"), local("OpenSans-600"), url("open-sans-v27-latin-600.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("open-sans-v27-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open sans 700 latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local("Open Sans 700"), local("OpenSans-700"), url("open-sans-v18-latin-700.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("open-sans-v18-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

a {
    color: $primary-color;
    transition: color 0.3s ease-in-out;
    font-weight: 700;
    text-decoration: none;
}

a:hover {
    color: $secondary-color;
    cursor: url('./cursor.png'), pointer;	
}

// show href value when a tag has no text value (https://twitter.com/AllThingsSmitty/status/946381631836901377)
a[href^="http"]:empty::before {
    content: attr(href);
}

// SVGs in current font color
svg {
    fill: currentColor;
}

details summary { 
    list-style: none;

    &:hover {
        cursor: url('./cursor.png'), pointer;	
    }
}

label:hover, button:hover {
    cursor: url('./cursor.png'), pointer;
}

.c-lead {
    p {
        font-weight: $lead-font-weight;
        font-size: $lead-font-size;
        line-height: $lead-lineheight;
    }
}

.c-subheadline {
    font-weight: bold;

    &--small {
        font-size: rem(20);
    }

    &--medium {
        @include fluid-type(360px, 1000px, 20px, 32px);
    }
}

h1 {
    @include fluid-type(360px, 1000px, 38px, 76px);
    text-transform: uppercase;
    letter-spacing: -0.01em;
}

h2, h3 {
    @include breakpoint (medium) {
        text-transform: uppercase;
    }
}

// declaring them position relative, for use with background images that are position absolute. This makes them visible.
h1, h2, h3, h4, h5, h6, p {
    position: relative;
}


