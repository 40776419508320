/* ==========================================================================
   layout
   ========================================================================== */

/**
 * Generic layout styling
 */


html {
    height: -webkit-fill-available;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    // mobile viewport bug fix: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    min-height: -webkit-fill-available;
    overflow-x: hidden;
}

// this is for fixing the footer to the bottom of the viewport (because of min-height not working in IE)
.l-page-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.l-page-header,
.l-page-footer {
    width: 100%; // needed for firefox and edge display error
    flex: 0 0 auto;
}

.l-content-wrapper {
    position: relative;
    flex: 1 1 auto;
    max-width: $content-wrapper-width;
    width: 100%;
    margin: auto;
}

.l-page-content {
    width: 100%;
    position: relative;
    // help visibilty of content on small screens
    @include breakpoint (small only) {
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%); 
    }
}

.grid-container {
   
    &.content {
        // Added positioning to get content visible over grunge-divider
        position: relative;
        z-index: 1;
    }
    
    @include breakpoint(medium only) {
        max-width: calc(100vw - 2rem);
    }
}

.grid-background {
    position: relative;
}

img {
    max-width: 100%;
    object-fit: cover;
}

main {
    > .grid-background:last-of-type {
        padding-bottom: rem(128);
        
        @include breakpoint(medium) {
            padding-bottom: rem(150);
        }
    }
}

// changes to allow for overlapping of content over footer
.l-page-content {
    top: rem(80);

    @include breakpoint (large) {
        top: rem(70);
    }
}

.l-content-wrapper {
    padding-top: rem(12);

    @include breakpoint (large) {
        padding-top: rem(44);
    }
}

