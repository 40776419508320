/* ==========================================================================
   News
   ========================================================================== */

   .c-news {
        
        &__wrapper {
            position: relative;
            height: 100%;
            margin: auto;
            background-color: $light-gray;
        }

        &__image {
            max-width: 100%;
            max-height: rem(152);
            overflow: hidden;

            @include breakpoint (medium) {
                max-height: rem(195);
            }

            img {
                transform: scale(1);
                transition: transform 0.7s ease-in-out;
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }

        &__headline {
            margin-top: 0;
            font-size: rem(18);
        }

        &__content {
            padding: rem(10 20 10 10);
            max-width: fit-content;
        }

        p {
            font-size: rem(13);
            color: $darkest-gray;
        } 

        .icon--news {
            position: absolute;
            bottom: rem(5);
            right: rem(10);
            width: rem(16);
            height: rem(28);
        }
}