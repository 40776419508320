/* ==========================================================================
   hero
   ========================================================================== */
$hero-bkgd-gradient: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 29%);
   
.c-hero {
    position: relative;

    &__wrapper {
        margin-bottom: rem(-160);
        transform: translateY(-172px); 

        @include breakpoint (large) {
            transform: translateY(-212px); 
            margin-bottom: rem(-190);
        }
    }

    &__logo {
        @include breakpoint (medium down) {
            width: rem(225);
        }
    }
    
    &__image-main {
        text-align: center; // needed to center the hero image on the page
        img {
            width: 100%;
        }
    }

    
    //-------------------- Hero with Text component on the startpage
    &__text {
        position: relative;
        z-index: 2;
        padding: rem(290 0 15 0);

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 8rem;
            right: -3rem;
            bottom: -4rem;
            left: -3rem;
            background: linear-gradient(180deg, rgba(get-color(primary),0) 0%, rgba(get-color(primary),0.5) 60%, rgba(get-color(primary),0) 100%);
            z-index: -1;
        }
            
        
        @include breakpoint (large) {
            padding: $global-margin*2 rem(20) 0 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%);
            max-width: 33%;

            &:after {
                display: none;
            }
        }
    }
    
    &__button {
        @include breakpoint (medium down) {
            text-align: center;
        }
    }
    
    &__heading {
        margin-top: 0; // normalizes h1 to the surrounding box
        text-transform: uppercase;
        color: $white;
        @include fluid-type (360px, 640px, 32px, 76px);
        letter-spacing: -0.01em;
        line-height: 1.05;
        
        @include breakpoint (small only) {   
            // Original comment: not so sure about this yet. Breaking up a word is not readable.
            // CS/2022-02-17: Don't use this! Add a  &shy;  to break large words in MODX instead
            //                ...this tells the browser where to break a word!
            //word-break: break-word;
        }

        @include breakpoint (large) {
            width: rem(722);
            padding: rem(8 10 10 0);
            background: $white;
            background: $hero-bkgd-gradient;
            margin-bottom: 0;
            color: $body-font-color;
        }
    }
    
    &__subheading {
        padding-left: rem(7); // lines up our content vertically with the h1 heading.
        
        h2 {
            line-height: 1.2;
            margin: 0;
            text-transform: none;
            color: $white;

            @include breakpoint (large) {
                color: $body-font-color;
            }

            @include fluid-type (360px, 1000px, 20px, 30px);
        }

        p {
            margin: rem(28 0 40 0);
            color: $white;
            
            @include breakpoint (large) {
                color: $body-font-color;
            }
            @include fluid-type (360px, 1000px, 18px, 20px);
        }

        .c-button {
            @include breakpoint (medium down) {
                margin: auto;
                background-color: $white;
                color: $primary-color;
            }
        }
    }
    
    &__tiretracks {
        position: relative;
        top: rem(-72);
        width: 100%;
        
        svg {
            width: 160%;
            height: auto;
            transform: rotate(-3deg);
        }
        
        @include breakpoint(medium) {
            svg {
                width: 100%;
            }
        }
    }
    
    // Variation used on start page. CSS overrides.
    &--text {
        padding-bottom: 5%; // this gives extra space at the bottom for the tire graphic       
        
        @include breakpoint (large) {
            position: unset; // allows image to flow freely
            display: flex; // increases height to height of grid-x wrapper
        }
        
        .c-hero__image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin-right: rem(-30);
            margin-left: rem(-30);
            z-index: -1; // hide behind other elements.
            
            @include breakpoint(medium) {
                margin-right: rem(-40);
                margin-left: rem(-40);
            }
            
            @include breakpoint(large) {
                right: -36%;
                margin: auto;
                left: rem(87);
                bottom: auto;
                overflow: hidden;
            }
            
        }
        
        // Small image placement tweak for a visual correction
        $hero-fix-breakpoint : map-get($breakpoints, large);
            @media screen and (min-width: $hero-fix-breakpoint) and (max-width: calc(#{$hero-fix-breakpoint} + 75px)) {
                
               .c-hero__image {
                   right: rem(-185); // Push image a bit to the left
               }
               
               .c-hero__text {
                   padding-left: rem(20);
                   margin-left: rem(-20);
                   background: $white; 
               }
               
               .c-hero__subheading p {
                   font-size: $paragraph-font-size;
               }
        }

        .c-hero__image-main {
            height: 100%; //  needed for portrait mobile display
            width: 100%;
            
            img {
                height: 100%; //  needed for portrait mobile display
                width: 100%;
                object-position: right;
            }
            
            @include breakpoint (large) {
                text-align: right;
                height: auto; // override mobile portrait height
                
                img {
                    max-height: rem(780);
                    height: auto;
                }
            }
        }
    }
}