/* ==========================================================================
   navigation
   ========================================================================== */

/**
 * Outer navigation
 */

 .c-header__nav {

     // control visibility
     @include visually-hidden;

     @include breakpoint(large) {
         @include visually-hidden-undo;
         
         width: 100%;
     }

    .c-nav {
        &__list {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0;
            list-style-type: none;
            gap: rem(20);
        }

        &__item {
            flex: 1 1 auto;
        }

        &__link {
            font-size: $nav-font-size;
            font-weight: bold;
            text-transform: uppercase;
            
            $nav-fix-breakpoint : map-get($breakpoints, medium);
            @media all and (min-width: $nav-fix-breakpoint) and (max-width: rem(1125)) {
                font-size: rem(16);
            }

            &:hover {
                color: cssvar(color-primary);
            }
        }
    }
}