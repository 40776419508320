/* ==========================================================================
   Logo Card
   ========================================================================== */

.c-aussteller-logos {
    margin-bottom: $global-margin*2;

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        max-width: rem(360);
        list-style: none;

        @include breakpoint(medium) {
            max-width: rem(1200);
        }
    }

    &__item-wrapper {
        display: flex;
        height: 100%;
    }

    &__item {
        position: relative;
        margin: rem(20 auto 0);
        width: rem(167);
        height: rem(129);
        background-color: $white;
        box-shadow: $box-shadow-light;

        @include breakpoint(medium) {
            width: rem(280);
            height: rem(215);       
        }

        img {
            width: rem(167);
            height: auto;
            margin: auto;
            padding: rem(10);

            @include breakpoint (medium) {
                width: rem(280);
            }
        }   
    }

    //variant for list of first 4 logos
    &__list--highlight {
        max-width: rem(490);

        @include breakpoint(medium) {
            max-width: rem(800);
        }

        .c-aussteller-logos__item {
            width: rem(235);
            height: rem(180);

            @include breakpoint (medium) {
                width: rem(380);
                height: rem(289);
            }
        
            img {
                width: rem(220);
                height: auto;
                padding: rem(10);

                @include breakpoint (medium) {
                    width: rem(380);
                }
            }
        }
    }
    
    //aussteller slider variant    
    &__item--slider {
        margin: 0;   
        
        img {
            width: rem(140);

            @include breakpoint (medium) {
                width: rem(220);
            }
        }
    }
}