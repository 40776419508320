/* ==========================================================================
   Soundmemory
   ========================================================================== */

@import './soundmemory-icons'; 

.c-soundmemory {
    text-align: center;
    overflow: hidden;

    @include breakpoint (large) {
        min-height: rem(950);
    }

    .slide-out {
        animation: slide-out-left .1s linear;
    }
      
    .slide-in { 
        animation: slide-in-right .1s linear;
    }

    @media (prefers-reduced-motion: no-preference) {
        .slide-out {
            animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        }
          
        .slide-in { 
            animation: slide-in-right 0.5s cubic-bezier(0.445, 0.050, 0.550, 0.950);
        } 

        .shake-horizontal {
            animation: shake-horizontal 0.7s linear;
        }

        .shake-vertical {
            animation: shake-vertical 0.7s linear;
        }

        .pulsate {
            animation: pulsate 0.5s ease-in-out both;
        }
    }

    &__pages {
        margin-left: 0;
        min-height: rem(450);
        list-style-type: none;

        @include breakpoint (medium) {
            min-height: rem(560);
        }
    }

    &__score {
        margin-bottom: 0;
        font-size: rem(20);
        text-transform: uppercase;
    }

    &__player-wrapper {
        min-height: rem(86);
    }

    .c-playbutton {
        @include breakpoint (medium) {
            margin-top: rem(60);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20);
        margin: rem(40 0);
        justify-content: center;
    }

    &__card {
        width: 46%;
        perspective: 1000px;
        height: fit-content;

        @include breakpoint (medium) {
            width: 32%;
        }

        &:hover {

            .c-soundmemory__text {
                background-color: $ra-blue;
                transition: .7s;

                p {
                    color: $white;
                }
            }
        }
    }

    &__card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d
    }

    &__card-front, &__card-back {
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
    }

    &__card-front {
        border-radius: rem(20);
        padding-top: rem(20);
        background: $white;
    }

    &__card-back {
        position: absolute;
        top: 0;
        border-radius: rem(20);
        transform: rotateY(180deg);
        background-repeat: no-repeat;
        background-position: center;

        @include breakpoint (small only) {
            background-size: 50%;
        }

        &--correct {
            background-color: $success-color;
            background-image: $correct-svg
        }

        &--wrong {
            background-color: $secondary-color;
            background-image: $wrong-svg;
        }
    }

    /* HIDE RADIO */
    [type=radio] { 
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked ~ .c-soundmemory__text  {
        background-color: $ra-blue;

        p {
            color: $white;
        }
    }

    &__image {
        height: rem(100);
   
        @include breakpoint (medium) {
           height: rem(200);
        }
         
        @include breakpoint (large) {
            height: rem(250);
         }
        
        img {
            width: auto;
            height: 100%;
            margin: auto;
            object-fit: contain;
        }
    }

    &__text {
        display: flex;
        width: 100%;
        min-height: rem(40);
        box-shadow: $box-shadow-dark;
        border-bottom-right-radius: rem(20);
        border-bottom-left-radius:  rem(20);
        -webkit-backface-visibility: hidden; /* Safari */

        @include breakpoint (medium) {
            min-height: rem(100);
        }

        p {
            margin: auto;
            font-weight: bold;

            @include fluid-type(400px, 1000px, 16px, 35px);
        }
    }

    &__button-help {
        width: fit-content;
        margin:  0 auto $global-margin auto;
        font-size: $paragraph-font-size;
        color: $white;
        padding: $global-margin / 2;
        background-color: get-color('alert');
        display: none;
    }

    h2 {
        max-width: rem(360);
        margin: auto;
        color: white;
        text-transform: uppercase;
    }

    &__result {
        @include breakpoint (small only) {
            background: transparent radial-gradient(circle, $ra-blue 0%, transparent 40%) 50% 30px/200% no-repeat;
        }

        background: transparent radial-gradient(circle, $ra-blue 0%, transparent 40%) 50% 30px no-repeat;
    }

    &__result-image {
        margin: auto;
    }

    .c-social-links {
        margin-top: rem(20);
    }
}