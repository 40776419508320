/* ==========================================================================
   Component figureimage - A figure tag with figcaption inside of it.
   ========================================================================== */

   
.c-figureimage {
   
   &__caption {
       position: absolute;
       bottom: 0;
       left: 0;
       right: 0;
       background-color: $primary-color;
       padding: $global-margin/2;
       color: $white;
   }

   &__caption[data-source=""] {
      display: none;
   }
}
