/* ==========================================================================
   icon-badges
   ========================================================================== */

.c-loadmore {
    display: flex;
    flex-direction: column;

    p {
        font-size: rem(18);
    }
}

.c-icon-badge {

    @include background-badge;

    &--scrollup {
        width: rem(45);
        height: rem(45);
        position: absolute;
        left: calc(50% - 25px);
        top: rem(-110);

        @include breakpoint (medium) {
            top: rem(-184);
            width: rem(50);
            height: rem(50);
            margin: auto;
            
        }

        .icon--scrollup {
            transform: rotate(-90deg);
            width: rem(30);
            height: rem(30);
        }
    }


    &--audio, &--pause {
        width: rem(50);
        height: rem(50);
        border: none;
        background-color: $ra-blue;

        svg {
            width: rem(30);
            height: rem(30);
        }
    }

    &--pause {
        svg {
            width: rem(25);
            height: rem(25);
        }
    }
}

.c-playbutton--soundmemory {

    .c-icon-badge {
        width: rem(80);
        height: rem(80);

        @include breakpoint (small only) {
            background-color: unset;
        } 

        @include breakpoint (medium) {
            width: rem(102);
            height: rem(102);
            &:hover {
                border: 2px solid $white;
            }
        }
    }

    .c-icon-badge--audio {
        svg {
            width: rem(80);
            height: rem(80);
        }
    } 

    .c-icon-badge--pause {

        svg {
            width: rem(40);
            height: rem(40);
        }
    }
}
