/* ==========================================================================
   Newsletter
   ========================================================================== */

.c-newsletter {
    padding: rem(40 0 80 0);
    background-color: $medium-blue;
    color: $white;

    &__heading {
        text-transform: uppercase;
        color: $white;
    }

    &__subheading {
        font-size: rem(20);
        font-weight: bold;
    }

    input {
        margin: 0;
        height: 100%;
        flex: 0 1 auto;
        color: $white;
        background-color: $light-blue;
        border: none;
        
        &::placeholder {
            color: $white;
            opacity: 1; 
        }
         &:focus {
            background-color: $light-blue;
            border: none;
            box-shadow: none;
        }
    }

    form {
        display: flex;
        justify-content: space-around;
        background-color: $light-blue;
        margin: auto;
        border: 1px solid $white;
        max-width: rem(315);
        height: rem(45);
    }
    
    button {
        margin: 0;
        flex: 0 0 rem(45);
        width: rem(45);
        height: rem(45);
    }

    .icon--rocket {
        width: rem(65);
        height: rem(65);
        fill: $white;
    }

    .icon--chevron {
        width: rem(25);
        height: rem(25);
        fill: $white;
    }
}
