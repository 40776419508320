/* ==========================================================================
   Acordion 
   ========================================================================== */

   
.c-accordion {
    margin: $global-margin 0;
   
    &__list {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: rem(20);
        align-items: start;
        justify-content: center;
       
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }

    &__item {
        display: flex;
        max-width: rem(580);
        width: 100%;
        background: $primary-color;
        color: $white;
        
        @include breakpoint(medium) {
            background-color: $ra-blue;
        }
    }

    details summary::-webkit-details-marker {
        display:none;
    }
    
    &__header {
        display: flex;
        position: relative;
        
        @include breakpoint(medium) {
            background: $primary-color;
            flex-wrap: wrap;
        }
    }
    
    &__heading {
       flex: 1 1 auto;
       max-width: fit-content;
       padding: rem(10 15);
       width: 100%;
       margin-right: auto;
    }
    
    &__icon {
        flex: 0 0 rem(40);
        width: rem(40);
    }

    &__image {
        flex: 0 0 rem(108);
        
        picture {
            height: 100%;
        }
        
        img {
            height: 100%;
            width: 100%;

            @include breakpoint (medium) {
                height: rem(127);
                width: 100%;
            }
        }
        
        @include breakpoint(medium) {
            flex: 1 0 100%;
        }
    }

    &__text {
        background: $ra-blue;
        padding: rem(20);
        
        @include breakpoint (medium) {
            padding: rem(20 27 0 15)
        }
    }

    &__subheading {
        margin-bottom: rem(2);
        font-size: rem(12);

        @include breakpoint (medium) {
            margin-top: rem(5);
        }
    }
    
    &__heading-text {
        font-weight: $header-font-weight;
        margin-bottom: 0;
    }

    details {
        width: 100%;
        
        &[open] {
            .c-accordion__icon svg {
                transform: rotate(90deg);
            }
            
            summary ~ * {
                animation: flipdown .5s ease-in-out;
            }
        }
    }
    
    details:not[open] {
        
        summary ~ * {
            opacity: 0;
            transform-origin: top center;
            transform: rotateX(-90deg);
        }
    }

    summary::marker {
        display: none;
    }
    
    .icon--chevron {
       width: rem(25);
       height: rem(25);
       position: absolute;
       top: calc(50% - 12px);
       
       @include breakpoint(medium) {
           top: unset;
           bottom: rem(25);
       }
    }
    
    a {
        color: $white;
        font-weight: $header-font-weight;
    }
}
