/* ==========================================================================
   Program Card: This CSS was refactored out of the slider component into it's
   own component for use in other website areas.
   ========================================================================== */

 .c-programm {

    @include breakpoint (medium down) {
        margin-right: rem(-30);
    }

    &__image-wrapper {
        position: relative;
        max-width: 100%;
        max-height: rem(138);
        height: 100%;
        overflow: hidden;

        @include breakpoint (medium) {
            max-height: rem(216);
        }

        &:hover {
            .c-programm__logo {
                transform: scale(1.2);
            }
        }

        p {
            position: absolute;
            bottom: 0;  
            left: 50%; 
            transform: translateX(-50%); 
            text-transform: uppercase;
            font-size: rem(10);
            font-weight: 600;
            color: $white;

            @include breakpoint (medium) {
                font-size: rem(14);
            }
        } 
    }
    
    &__background-image {
        width: 100%;
        height: 100%;
        filter: brightness(0.5);
    }

    &__logo-wrapper {
        position: absolute;
        top: 50%;
        left: 50%; 
        max-width: 80%;
        transform: translate(-50%, -50%); 
    }
    
    &__logo {
        transform: scale(1);
        transition: transform 0.7s ease-in-out;
    }
    
    &__text {
    
        @include breakpoint (small only) {
            margin: 0;
            padding-bottom: rem(40);
        }

        a {
            color: $medium-blue;
            font-weight: bold;

            &:hover {
                color: $secondary-color;
            }
        }
    }
    
    &__heading {
        font-size: rem(18);
        font-weight: bold;
        
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }

        @include breakpoint (medium) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            
            span {
                max-width: rem(210)
            }
        }

        time {
            font-size: rem(16);
            font-weight: normal;
        }
    
    }
    
    &__heading--allow-wrap {
        span {
            white-space: normal;
            max-width: initial;
        }
    }
    
    // Variations

    // Dark background
    &--dark-bkgd {
        
        a {
            color: $white;
            font-weight: $header-font-weight;
            
            &:hover {
                color: $secondary-color;
            }
        }
    }
}

//slider rules when not initialized
.splide.not-active {

    .c-programm__image-wrapper {
        margin: auto;
        max-width: rem(370);
        max-height: rem(216);

        p {
            font-size: rem(14);
        }
    }

    .c-programm__text {
        margin: auto;
        max-width: rem(370);
    }
}