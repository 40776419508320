/* ==========================================================================
   Footer layout
   ========================================================================== */

/**
 * Specific footer styling
 */

.l-page-footer {
    position: relative;
    z-index: 1;
    color: $white;
    background: transparent linear-gradient(180deg, #003167 0%, #222338 100%) 0% 0% no-repeat;
    padding-top:  map-get($grid-column-gutter, small);

    @include breakpoint(medium) {
        padding-top: rem(70);
    }
}

// Footer content layout
.l-footer-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: rem(40);

    @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5%;
        text-align: left;
        align-items: flex-start;
    }
    
    &__col {
        flex: 0 1 auto;
        @include breakpoint(small only) {
            padding: map-get($grid-column-gutter, small) 0;
        }
        
    }
    
    &__col:nth-child(2){
        // This is the column with the logos and social media links
        display: flex;
        flex-direction: column;
        .c-logo, .c-footer__social-media {
            flex: 1 1 auto;
        }
        
        .c-footer__nav {
            margin: rem(58 0 50);
        }
        
        // Medium Up Basics
        @include breakpoint(medium) {
            order: 3;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            max-width: rem(275);
            
            .c-logo {
                flex-basis: 66%; // handles the rockantenne logos
            }
            
            .c-logo:first-of-type {
                flex-basis: 33%; // handles the ride online logo
            }
            
            .c-logo--rockantennes {
                order: 2;
                padding-left: rem(20);
            }
            
            .c-footer__social-media {
                order: 3;
                width: 100%;
            }
        }
        
         @media screen and (min-width: rem(1180)) {
            
            .c-logo {
                flex-basis: auto; // handles the rockantenne logos
                margin-bottom: $global-margin;
            }
            
            .c-logo:first-of-type {
                flex-basis: auto // handles the ride online logo
            }
            
            ul.c-social-links {
                margin: auto;
            }
            
            .c-logo--rockantennes {
                padding-left: inherit;
            }
            
        }
        
        // Specific medium styles
        $footer-fix-medium-breakpoint : map-get($breakpoints, medium);
        @media screen and (min-width: $footer-fix-medium-breakpoint) and (max-width: rem(1179)) {
            max-width: inherit;
            text-align: center;
            margin-top: map-get($grid-column-gutter, small);
            .c-social-links {
                margin: auto;
            }
        }

    }
}

// Logo ROCKANTENNE Grid used in footer

.l-ra-logo-grid {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10%;
    
    &__col {
        flex: 1 1 auto;
    }
    
    @include breakpoint (small only) {
        .c-logo__image--rockantenne {
            margin-top: rem(-2); // aligns the logo with hamburg logo
        }
    }

}