/* ==========================================================================
#Animations
========================================================================== */

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
  

@keyframes slide-out-left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes shake-horizontal {
    0%,
    100% {
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        transform: translateX(10px);
    }
    80% {
        transform: translateX(8px);
    }
    90% {
        transform: translateX(-8px);
    }
}
  
@keyframes shake-vertical {
    0%,
    100% {
        transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
        transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
        transform: translateY(8px);
    }
    80% {
        transform: translateY(6.4px);
    }
    90% {
        transform: translateY(-6.4px);
    }
}
  
@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}