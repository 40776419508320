/* ==========================================================================
   Sticky Button
   ========================================================================== */

.c-sticky-button {
    position: fixed;
    right: 0;
    top: 20%;
    z-index: 2;
    height: rem(60);
    width: rem(90);
    padding: rem(8);
    background-color: $white;
    border-radius: rem(4 0 0 4);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    a {
        color: $body-font-color;
        font-weight: bold;
    }

    p {
        font-size: rem(14);
    }

    span {
        padding: rem(0 2);
        color: $white;
        background-color: map-get($social-colors,youtube);
        border-radius: rem(4);
    } 
}