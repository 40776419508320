/* ==========================================================================
   Footer navigation
   ========================================================================== */

/**
 * Outer navigation
 */

 .c-footer {

    &__nav {
        
        ul {
            display: flex;
            flex-direction: column;
        }

        li {
            margin-top: rem(10);
            font-size: $nav-font-size;
            line-height: $nav-line-height;
            letter-spacing: $nav-letter-spacing;
        }

        a {
            color: $white;
            line-height: 2;
        }
    }

    &__subnav {
        margin-bottom: rem(50);
        
        @include breakpoint (medium) {
            margin-bottom: rem(20);
        }
        
        ul {
            display: flex;
            width: 100%;
            justify-content: center;
            font-size: rem(12);
            flex-wrap: wrap;
            
            li:not(:first-of-type)  {
                :before {
                    content:"|";
                    vertical-align: middle;
                    padding: rem(0 5);
                }
            }

            a {
                font-size: rem(12);
            }
        }
    }

}
