// - - - - - - - - - - - - - - - - - - - - - - - - -
//  Social Images Styling
// - - - - - - - - - - - - - - - - - - - - - - - - -

.c-instagram-feed {
    text-align: center;

    &__list { 
        display: flex; 
        margin: 0 0 rem(20) 0;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
    
        @include breakpoint (medium) {
            margin: rem(0 auto 20);
            gap: rem(20);
        }
    }

    &__item {
        flex: 1 1 50%;
        padding: rem(5);
        
        @include breakpoint(medium) {
            flex: 0 1 20%;
            padding: unset;
            
            a:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        @media (prefers-reduced-motion: no-preference) {
            &.u-visually-hidden {
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &.u-visually-hidden-undo { 
                opacity: 1;
                transition: all 0.3s ease-in;
            } 
        }

        img {
            width: 100%;
            height: auto;
            transform: scale(1);
            transition: transform 0.7s ease-in-out;
        }
    }
    
   
}
