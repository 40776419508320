.c-embed {
    margin: 0 0 $global-margin;
    background: get-color('light-gray');
    color: $body-font-color;
    text-align: center;

    > * {
        transition: opacity 0.3s ease-in-out;
    }

    xmp {
        display: none;
    }

    &__posts {
        list-style: none;
        margin-bottom: 0;
    }

    &__post {
        height: 100%;

        @media (prefers-reduced-motion: no-preference) {
            &.u-visually-hidden {
                opacity: 0;
                transition: opacity 0.5s ease-out;
            }

            &.u-visually-hidden-undo { 
                opacity: 1;
                transition: opacity 0.5s ease-in;
            } 
        }
    }

    .uc-heading, .uc-title {
        font-size: $paragraph-font-size;
        color: $primary-color;
        margin: rem(20) 0 rem(5);
    }

    a {
        font-weight: $header-font-weight;
        color: $body-font-color;
    }

    .c-embed__title {
        font-size: $header-font-size;
        line-height: $header-lineheight;
        font-weight: $header-font-weight;
        color: $primary-color;
    }

    .c-embed__preview {
        display: block;
        border: rem(1) solid $primary-color;
        padding: $global-margin;
        background: get-color('light-gray');
    }

    .c-embed__preview.hide {
        display: none;
    }

    .c-embed__optinbutton {
        .uc-embed {
            display: none !important;
        }

        .uc-optInCheckboxWithLabel.uc-title {
            display: inline-block;
            margin: 0;
            padding: 0;
            font-size: $paragraph-font-size;
            font-weight: $header-font-weight;
        }
    }

    .c-embed__processordetails {
        display: none;
        margin-top: $global-margin;

        .uc-optInCheckboxWithLabel {
            display: none !important;
        }
    }

    .c-embed__details:target .c-embed__processordetails {
        display: block;
    }
    .c-embed__details:target .c-embed__processorlink, .c-embed__details:target .c-embed__processor {
        display: none;
    }

    .c-embed__processor {
        min-height: rem(54);
    }

    .c-embed__processor, .c-embed__processor .uc-heading {
        font-size: $small-font-size;
        line-height: 1.4;
        margin: 0;
    }

    .c-embed__details .uc-furtherInformation {
        font-size: $paragraph-font-size;
    }

    .c-embed__live {
        display: none;
    }

    iframe {
        max-width: 100%;
    }
  
    // Variations
    &--youtube .c-embed__live iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    &--twitter .c-embed__live iframe {
        width: 100% !important;
    }
    
    &.is-initialized {
        .c-embed__live {
            display: block;
        }
    }
    
    &--small {
        .c-embed__preview {
            padding: rem(10);
            
            p {
                font-size: rem(13);
            }
        }
        
        .c-embed__processorlink, .c-button, .uc-heading, .c-embed__title {
            font-size: rem(15);
        }
        
        .c-embed__title, .c-button {
            margin-bottom: rem(5);
        }
        
    }
}
html.antenneembed-consent-available .c-embed {
    background: transparent;
    
    // only add extra padding when user has accepted consent and video can be played
    &.c-embed--youtube .c-embed__live {
        position: relative;
        height: 0;
        padding-bottom: calc(var(--aspect-ratio, .5625) * 100%); 
        overflow: hidden;
    }
}
