/* ==========================================================================
   Subnav
   ========================================================================== */

.c-subnav {
    position: relative;
    z-index: 2;
    width: 105%;
    margin-bottom: rem(-58);
    opacity: 0;
    overflow-x: auto;
    animation: appear 1s linear;
    animation-fill-mode: forwards;  
    transform: translateY(rem(-58));

    &__list {
        display: flex;
        margin: 0;
        list-style: none;
    } 

    &__item {
        min-width: fit-content;
    }

    .c-button {
        margin: 0;
        padding: rem(20 20);
        background-color: $ra-blue;
        border: 1px solid $ra-blue;
        font-size: rem(20);
        color: $white;

        @include breakpoint (large) {
            padding: rem(20 30);
        }
    }
}