/* ==========================================================================
 Generic Form Styles
========================================================================== */


label {
    font-weight: 700;
    font-size: $paragraph-font-size;
    color: $body-font-color;
}

::placeholder {
    color: $body-font-color;
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    padding: rem(4);
    border: rem(1) solid get-color(primary);

    &:focus {
        border-top: none;
        border-right: none;
        border-left: none;
        
        &::placeholder {
            color: get-color(primary);
        }
    }
}

textarea {
    min-height: rem(320);
}

select {
    display: inline-block;
    text-indent: rem(5);
    width: 100%;
    background-color: transparent;
    border: none;
    border: rem(1) solid get-color(primary);
    color: $body-font-color;
    margin: rem(0 0 15 0);
    padding: rem(0 30 0 0);
    background-size: rem(20) rem(30);
    background-position: right rem(-24) center;
    background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="Capa_1" transform="matrix(4.99683e-17,0.816044,-0.816044,4.99683e-17,109.571,-38.1326)"><path d="M139.179,73C139.179,72.143 138.75,71.179 138.107,70.536L88.179,20.607C87.536,19.964 86.571,19.536 85.714,19.536C84.857,19.536 83.893,19.964 83.25,20.607L77.893,25.964C77.25,26.607 76.821,27.571 76.821,28.429C76.821,29.286 77.25,30.25 77.893,30.893L120,73L77.893,115.107C77.25,115.75 76.821,116.714 76.821,117.571C76.821,118.536 77.25,119.393 77.893,120.036L83.25,125.393C83.893,126.036 84.857,126.464 85.714,126.464C86.571,126.464 87.536,126.036 88.179,125.393L138.107,75.464C138.75,74.821 139.179,73.857 139.179,73Z" style="fill-rule:nonzero;fill:#{$primary-color}"/></g></svg>');
    
    &:focus {
        border: none;
        border-bottom: rem(1) solid get-color(primary);
        outline: none;
    }

    &.has-selected {
        color: $body-font-color;
    }
}

.c-field {
    margin-bottom: $global-margin;
}

.c-field--checkbox {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 rem(12);
    cursor: pointer;
    font-weight: $global-weight-normal;
    padding-left: rem(26);
    line-height: 1.4;

    & > [type='checkbox'] {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        pointer-events: none;
        &:focus {
            ~ .c-field--checkbox__checkelement  {
                box-shadow: 0 0 0 0.1875rem get-color(primary);
            }
        }
    }

    &__checkelement {
        z-index: 2;
        width: rem(18);
        height: rem(18);
        display: inline-block;
        background-color: $white;
        border: 0.124rem solid get-color(primary);
        position: absolute;
        top: 2px;
        left: 0;
        transition: background-color .03s ease-in-out;
        cursor: pointer;
        box-shadow: 0 0 0 4px $white;

        ~ small {
            position: relative;
            top: 2px;
        }

    }
}

input[type="checkbox"]:checked ~ .c-field--checkbox__checkelement  {
    background-color: $white;
    background-size: rem(13) rem(13);
    background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="noun_1780646_cc" fill="#666666"><polygon id="Shape" points="14.997 32.6956 0.99728 45.8307 31.4877 87.694 90.9992 13.2639 77.8156 0.30596 31.4877 57.4269"></polygon></g></svg>');
    background-repeat: no-repeat;
    background-position: center center;
}


// Errors

.error * {
    color: get-color('alert');
    font-weight: 700;
}

.success * {
    color: get-color('success');
    font-weight: 700;
}


.formmessage {
    text-align: center;
    margin: $global-margin 0;
    
    legend {
        display: block;
        margin-top: $global-margin;
    }
    
    &.error {
        border: rem(1) solid get-color('alert');
    }
    
    &.success {
        border: rem(1) solid get-color('success');
    }
}
