/* ==========================================================================
   Product Card
   ========================================================================== */

.c-product {

    &__card {
        position: relative;
        max-width: max-content;
        margin: auto;
        background: $white;
        box-shadow: $box-shadow-light;
        min-height: 100%;
    }
    
    img {
        width: 100%;
    }
    
    &__text {
        max-width: rem(226);
        padding: rem(15);

        @include breakpoint (medium) {
            max-width: rem(365);
            padding: rem(15 21);
        }
    }
    
    &__heading {
        margin-bottom: 0;
        font-size: rem(18);
        font-weight: bold;
    }

    &__subheading {
        margin-bottom: rem(40);
        font-size: rem(14);
    }

    &__description {
        margin: rem(25 0 0);
        font-size: rem(14); 
    }
    
    &__details {
        display: flex;
        margin: 0;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: rem(10);
    
        li {
            display: flex;
            flex-direction: column;
        }
    }
    
    &__key {
        margin-bottom: 0;
        font-size: rem(12);
        font-weight: normal;
    }
    
    &__value {
        margin-bottom: 0;
        font-weight: bold;
        font-size: rem(26);

        span {
            font-size: rem(12);
            font-weight: normal;
        }
    }

    .c-playbutton {
        position: absolute;
        top: rem(10);
        right: rem(10);
    }

    &--single {
        @include breakpoint (small only) {
            margin-left: rem(-30);
            margin-right: rem(-30);  
        }
   
        @include breakpoint (large) {
            margin-left: auto;
            max-width: rem(390);
        }

        img {
            max-width: 100%;
        }

        .c-product__text {
            max-width: rem(375);
            padding: rem(25 50 15);
    
            @include breakpoint (medium) {
                padding: rem(25 33 15);
            }
        }
    }
}

//slider rules when not initialized
.splide.not-active {

    .c-product__card {
        img {
            max-width: rem(365);
            max-height: rem(246);
        }
    }
    
    .c-product__text {
        max-width: rem(365);
        padding: rem(15 21);
    }
}