// Lazyload styles
.u-lazyload, .is-lazyloading {
    opacity: 0;
}

.is-lazyloaded {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.no-js img.u-lazyload, .no-js picture.u-lazyload {
    display: none;
}

// if no javascript show our elements anyway. In this case, we are using lazyloading for fundamental content.
.no-js .u-lazyload {
    opacity: 1;
}