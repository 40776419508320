.o-footer-bg {
    position: relative;
    z-index: 2;
    width: 100%;
    height: rem(50);
    background-position: center;
    background-size: 535px 62px;
    
    @include breakpoint(medium) {
        height: rem(109);
        background-position: center;
        background-size: 1920px 109px;
    }
}

/* Because of the different use cases, and it being in the header which 
 *  would get loaded quickly anyway via lazyloading
 *  decided to load graphics directly
*/
.o-header-bg--left {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: rem(92);
    background-image: url('/dist/rideonline/bg_header_small@2x.jpg');
    
    @include breakpoint(large) {
        background-image: url('/dist/rideonline/bg_header_large_left@2x.webp');
        height: rem(114);
        background-size: 565px 128px;
        background-repeat: no-repeat;
    }
}

.o-header-bg--right {
    display: none;
   
    @include breakpoint(large) {
        background-image: url('/dist/rideonline/bg_header_large_right@2x.webp');
        display: block;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: rem(328);
        height: rem(114);
        background-size: 565px 128px;
        background-repeat: no-repeat;
    }
}

.o-hero-bg {
    position: absolute;
    margin-top: rem(-80);
    background-position: top left;
    background-size: 640px 160px;
    height: rem(160);
    width: 100%;
    
    @include breakpoint(medium) {
        background-position: top center;
        background-size: 1920px 149px;
        height: rem(149);
    }
}

.o-grunge-divider {
    position: absolute;
    z-index: 1;
    margin-top: rem(-75);
    background-position: top left;
    background-size: 640px 160px;
    height: rem(160);
    width: 100%;
    
    @include breakpoint(medium) {
        margin-top: rem(-92);
        background-position: top center;
        background-size: 1920px 149px;
        height: rem(149);
    }
}

// Used with the RO Heading component
.o-tiretrack {
    @include breakpoint(small only) {
        position: relative;
        margin: rem(-60 -20 0 -20);
        background-repeat: repeat-x;
        background-position: top left;
        transform: rotate(5deg);
        height: rem(115);
    }
}

.o-bg-content-left {
    position: relative;
    background-position: top left;
    background-repeat: repeat-y;
    background-size: 100% auto;
    height: 100%;
    
    @include breakpoint(large) {
        background-size: rem(300) auto;
    }
}
.o-bg-content-right {
    position: relative;
    background-position: top right;
    background-repeat: repeat-y;
    background-size: rem(300) auto;
    height: 100%;
}

// Gives anchor scrolling to sections necessary spacing above content to make up for fixed position header
.o-anchor-scroll-target {
    position: absolute;
    top: rem(-92);
    width: 1px;
    height: 1px;
    
    @include breakpoint(medium){
        top: rem(-114);
    }
}