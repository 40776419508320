//--- hide visually ---//
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
  
@mixin visually-hidden-undo {
  position: relative;
  width: inherit;
  height: inherit;
  overflow: inherit;
  clip: auto;
}

@mixin background-badge {

  @include badge;

  width: rem(40);
  height: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $white;
  background-color:rgba(0, 49, 103, 0.8);

  svg {
    fill: $white;
  }

  &:hover {
    background-color: rgba(0, 49, 103, 1);;
  }
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
