/* ==========================================================================
    CSS based slider
   ========================================================================== */

/**
 * c-slider
 * Borrowing ideas from https://css-tricks.com/can-get-pretty-far-making-slider-just-html-css/
 */

.c-slider {
    padding: rem(40 0);
   
    @include breakpoint(medium) {
         padding: rem(50 0);
    }

    &__background {
        height: rem(313);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include breakpoint (large) {
            height: rem(611);
        }
    }
    
    &__video-wrapper {
        /*
        * When embedding youtube videos into the slider, finger swiping does not work
        * A work around here is to add padding around the video to give the finger a place to trigger.
        * Could not find a better workaround as yet.
        */
        padding: rem(10);
        @include breakpoint(large) {
            padding: unset;
        }
    }

    .splide__track {
        padding-bottom: rem(10);
    }

    .splide__arrows {
        @media only screen and (max-width: 1370px) {
            display: none;
        }
    }

    .splide__arrow {
        @include background-badge;
    }

    .splide__arrow--prev {
        left: rem(-90);
    }

    .splide__arrow--next {
        right: rem(-90);
    }

    .splide__pagination__page {
        width: rem(49);
        height: rem(2);
        margin: 0 0.25em;
        border-radius: 0;
        background-color: $dark-gray;
        transition: all 300ms ease-in-out;
        cursor: url('./cursor.png'), pointer;

        &:focus:not(:focus-visible) {
            outline: none;
        }
        //for keyboard user
        &:focus-visible {
            outline: 3px dashed $primary-color;
        }

        &:hover {
            border: 2px solid $primary-color;
            background-color: $primary-color;
        }

        &.is-active {
            background-color: $primary-color;
        }
    }

    //videos variant
    &--videos {
        @include breakpoint (small only) {
            margin-right: rem(-40);
        }

        &.has-background {
            position: relative;
            margin: rem(0 auto -200);
            top: rem(-200);

            @include breakpoint (small only) {
                margin-right: rem(-30);
            }

            @include breakpoint (medium) {
                margin: rem(0 auto -220);
                top: rem(-220);
            }
        
            @include breakpoint (large) {
                margin: rem(0 auto -280);
                top: rem(-280);
            }

            .h3 {
                margin-bottom: rem(20); 
                color: $white;
            }

            .splide__arrow {
                top: 60%;
                transform: translateY(-60%); 
            }
        }

        .c-programm__image-wrapper {

            &:hover {
                .icon--play {
                    opacity: 1;
                }
            }
        }

        .icon--play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: rem(50);
            width: rem(50);
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ccc;
            border: 0;
            border-radius: 50%;
            cursor: url('./cursor.png'), pointer;
            opacity: 0.6;
            transition: opacity 0.1s linear;

            @include breakpoint (large) {
                height: rem(68);
                width: rem(68);
            }

            &:after {
                content: "";
                display: inline-block;
                margin-left: 4px;
                border-color: transparent;
                border-style: solid;
                border-width: rem(8 0 8 11);
                border-left-color: $dark-blue;

                @include breakpoint (large) {
                    border-width: rem(12 0 12 18);
                }
            }
        }
    }

    //images variant
    &--images  {

        @include breakpoint (small only) {
            margin: rem(0 -20);
        }

        .splide__slide__container {
            height: 100%;
        }

        img {
            width: 100%;
            max-height: rem(255);

            @include breakpoint (medium) {
                max-height: rem(664);
            }
        }
    }

    //products variant
    &--products {
        
        &.is-initialized .c-product__card {
           min-width: rem(250);
        }
        
        .c-slider__item  {
            
            &.is-prev, &.is-next {
            
                @include breakpoint (medium) {
                    pointer-events: none;
                    filter: opacity(0.3);
                }

                .c-playbutton {
                    @include visually-hidden;
                }
            }
        }
    }

    //news variant
    &--news {
        @include breakpoint (medium down) {
            margin-right: rem(-30);
        }
    }

    //aussteller variant
    &--aussteller {
        padding-top: rem(20);
    }
}

//slider rules when not initialized
.splide.not-active {
    visibility: visible;
    margin-right: 0;

    .splide__list {
        justify-content: center;
        gap: rem(20);

        @include breakpoint (small only) {
            flex-wrap: wrap;
        }
    }

    .splide__slide {
        width: 100%;

        @include breakpoint (medium) {
            width: 30%
        }
    }
}


// Slider when in various column layouts.. better ideas? Cooler would be a SASS loop but we are racing against budget.

.cell.large-4, .cell.large-5, .cell.large-6, .cell.large-7, .cell.large-8 {
    
    .c-slider .splide__arrow--prev {
        left: rem(20);
    }
    
    .c-slider .splide__arrow--next {
        right: rem(20);
    }
}

.cell.large-4 {

    .c-slider--images img {
        max-height: rem(143);
    }
    
}

.cell.large-6 {
    
    .c-slider--images img {
        max-height: rem(350);
    }
    
}

.cell.large-5 {
    
    .c-slider--images img {
        max-height: rem(287);
    }
    
}

.cell.large-7 {

    .c-slider--images img {
        max-height: rem(412);
    }
    
}

.cell.large-8 {

    .c-slider--images img {
        max-height: rem(475);
    }
    
}

