/* ==========================================================================
   Menu Button
   ========================================================================== */

/**
 * c-menu-button
 * The beloved hamburger button for mobile. It animates the link. Animation taken from here:
 * https://github.com/jonsuh/hamburgers/blob/master/dist/hamburgers.css
 */

 .c-menu-button {
     margin-left: auto;

    @include breakpoint(large) {
        display: none;
    }

    &__button {
        padding: rem(10 0 10 10);
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
        outline: none;

        &:hover {
            opacity: 0.7;
        }

        &.is-active {
            &:hover {
                opacity: 0.7;
            }

            .c-menu-button__inner {
                transform: rotate(45deg);
                transition-delay: 0.12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            .c-menu-button__inner {
                &:before {
                    top: 0;
                    opacity: 0;
                    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
                }

                &:after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
        }
    }

    &__box {
        width: rem(30);
        height: rem(24);
        display: inline-block;
        position: relative;
    }

    &__inner {
        display: block;
        top: 50%;
        margin-top: -2px;

        // Squeeze effect
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &__inner,
    &__inner:before,
    &__inner:after {
        width: rem(30);
        height: rem(2);
        background-color: $primary-color;
        border-radius: rem(4);
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &__inner:before,
    &__inner:after {
        content: "";
        display: block;
    }

    &__inner:before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        top: -10px;
    }

    &__inner:after {
        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        bottom: -10px;
    }
}

.c-mobile-nav {
    z-index: 12;
    position: absolute;
    right: 0;
    background: #fff;
    box-shadow: $box-shadow-dark;
    top: rem(80);
    width: 100%;
    padding: rem(6 0);
    border-radius: 0 0 $global-radius 0;
    animation: flipdown .5s ease-in-out;


    &:before {
        content: "";
        display: block;
        position: absolute;
        top: rem(-7);
        left: rem(-7);
        right: rem(-7);
        height: rem(12);
        background: #fff;
    }

    &.is-off-screen {
        display: none;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: rem(0 10);
        text-align: left;
    }

    &__item {
        border-bottom: 1px solid $light-gray;

        a {
            display: flex;
            padding: rem(17 10);
            align-items: center;
            justify-content: space-between;
        }
    }

    .icon--chevron {
        width: rem(20);
        height: rem(20);
        vertical-align: sub;
    }
}